// extracted by mini-css-extract-plugin
export var aArrow1 = "Wc";
export var aArrow2 = "Xc";
export var aEmpty = "Mc";
export var aPrice = "Pc";
export var aTagAnnounce = "Tc";
export var aTagClose = "Sc";
export var aTagEntry = "Qc";
export var aTagFree = "Vc";
export var aTagLottery = "Uc";
export var aTagPayment = "Rc";
export var aText = "Yc";
export var aTitle = "Nc";
export var animationMarquee = "bd";
export var pDetail = "ad";
export var pItemBatch = "Zc";
export var pItemBatchAbs = "_c";
export var pItemList = "Oc";