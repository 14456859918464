// extracted by mini-css-extract-plugin
export var aArrow1 = "oc";
export var aArrow2 = "pc";
export var aButton1 = "Zb";
export var aButton2 = "_b";
export var aButton3 = "ac";
export var aButton4 = "bc";
export var aButton5 = "cc";
export var aButtonHeaderLogin = "dc";
export var aButtonHeaderRegister = "ec";
export var aButtonHeaderSpLogin = "hc";
export var aButtonLogin = "gc";
export var aButtonRegister = "fc";
export var aEmpty = "Ub";
export var aInner = "Xb";
export var aMarquee = "Yb";
export var aTagAnnounce = "lc";
export var aTagClose = "kc";
export var aTagEntry = "ic";
export var aTagFree = "nc";
export var aTagLottery = "mc";
export var aTagPayment = "jc";
export var aText = "qc";
export var aTitle = "Vb";
export var animationMaquee = "rc";
export var animationMarquee = "sc";
export var pMarqueePageTitle = "Wb";